export default class MaxPlanThresholdReachedError extends Error {
  constructor(error) {
    super(error);
    this.errorMessage = error;
  }
  dispatchFlahsNotification() {
    let event = new CustomEvent("flashMessage", {
      detail: { type: "error", message: this.errorMessage },
      bubbles: true,
    });
    document.dispatchEvent(event);
  }
}
