<script setup lang="ts">
import AbandonedCart from "@/models/AbandonedCart";

const emit = defineEmits(["show"]);

defineProps<{
  show: boolean;
}>();

const onboardingStore = useOnboardingStore();
const menusStore = useMenusStore();
const router = useRouter();
const selectedPlan = ref();

const currentSelectedPlan = computed(() => {
  return onboardingStore.selectedPlan;
});

function selectPlan(planId: string) {
  selectedPlan.value =
    planId && onboardingStore.plans.find((plan) => plan.id == planId);
  submit();
}

function closePopup() {
  emit("show", false);
}

async function submit() {
  if (
    currentSelectedPlan.value &&
    currentSelectedPlan.value.id == selectedPlan.value.id
  ) {
    closePopup();
    return close();
  }
  if (
    currentSelectedPlan.value &&
    currentSelectedPlan.value.threshold > selectedPlan.value.threshold
  ) {
    await downgrade();
  } else {
    await upgrade();
  }

  // Update the url to the new plan
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      plan: selectedPlan.value.slug,
    },
  });

  close();
  closePopup();
}
async function downgrade() {
  await onboardingStore.UPDATE_PLAN({
    planId: selectedPlan.value.id,
    sourceUrl: window.location.href,
  });
}
async function upgrade() {
  await onboardingStore.UPDATE_PLAN({
    planId: selectedPlan.value.id,
    sourceUrl: window.location.href,
  });
  if (menusStore.pendingCartItem && menusStore.pendingCartItem.sku) {
    await abandonedCart.value.addProduct(
      menusStore.pendingCartItem,
      window.location.href
    );
  }
}
function close() {
  menusStore.SET_PRODUCT_ABOUT_TO_BE_ADDED_TO_CART(null);
}
const abandonedCart = computed(() => {
  return new AbandonedCart(onboardingStore.$state);
});

watch(
  () => currentSelectedPlan.value,
  (newSelectedPlan) => {
    selectedPlan.value = newSelectedPlan;
  }
);

onMounted(() => {
  selectedPlan.value = currentSelectedPlan.value;
});
</script>
<template>
  <div
    class="tw-absolute tw-z-10 tw-top-full tw-w-full tw-left-0 tw-pt-[9px] tw-opacity-0 tw-invisible tw-transition-all group-hover/drop:tw-opacity-100 group-hover/drop:tw-visible"
  >
    <ul
      class="tw-bg-white tw-p-2 tw-rounded-[7px] tw-text-[16px] tw-leading-none tw-font-medium tw-text-left tw-shadow-nl-12"
    >
      <li v-for="plan in onboardingStore.plans" :key="plan.id">
        <a
          href="#"
          @click.prevent="selectPlan(plan.id)"
          class="tw-block tw-py-3 tw-px-2 tw-rounded-[7px] hover:tw-bg-[#e7f2ed] hover:tw-font-bold tw-transition-all"
          ><span
            ><span>{{ plan.threshold }}</span>
            <span v-if="plan.can_exceed_threshold">{{
              plan.can_exceed_threshold ? "+" : ""
            }}</span
            >&nbsp;items
            <span v-if="plan.description">({{ plan.description }})</span></span
          ></a
        >
      </li>
    </ul>
  </div>
</template>
<style></style>
